<template>
  <div class="divBox">
    <v-list
      ref="list"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
    >
      <template #headBtnSlot>
        <v-button text="重置" @click="reset"></v-button>
        <v-button text="新增" @click="handleAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="姓名" v-model="searchParam.name" />
        <v-select
          clearable
          placeholder="请选择"
          :options="releaseStatus"
          v-model="searchParam.status"
          @change="$refs.list.search()"
          label="发布状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="编辑" type="text" @click="handleEdit(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
    <v-dialog
      v-model="dialogFormVisible"
      width="80%"
      :title="dialogTitle"
      sureTxt="保存"
      @confirm="saveEdit"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="姓名">
          <v-input
            clearable
            :width="500"
            class="filter-item"
            v-model="temp.name"
          ></v-input>
        </el-form-item>
        <el-form-item label="性别">
          <v-radio :radioObj="sexObj" v-model="temp.sex"></v-radio>
        </el-form-item>
        <el-form-item label="简介" prop="introduction">
          <div id="editor">
            <v-ueditor :maxLength="2000" v-model="temp.introduction" />
          </div>
        </el-form-item>
        <el-form-item label="是否置顶">
          <v-radio :radioObj="isTopObj" v-model="temp.isTop"></v-radio>
        </el-form-item>
        <el-form-item label="置顶排序" v-if="temp.isTop == 1">
          <v-input
            clearable
            :width="200"
            class="filter-item"
            v-model="temp.isTopSort"
          ></v-input>
        </el-form-item>
        <el-form-item label="发布状态">
          <v-select :options="releaseStatusOps" v-model="temp.status" />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  saveCpbTeacherInfoUrl,
  getCpbTeacherListUrl,
  deleteCpbTeacherUrl,
} from "./api.js";
import {
  releaseStatusMap,
  releaseStatus,
  releaseStatusOps,
  sexStatus,
  sexStatusOps,
  sexStatusMap,
} from "./map.js";

export default {
  name: "teacherLibrary",
  data() {
    return {
      releaseStatusOps: releaseStatusOps(),
      releaseStatus,
      sexStatus,
      dialogTitle: null,
      dialogFormVisible: false,
      isTopObj: [
        { name: 0, value: "否" },
        { name: 1, value: "是" },
      ],
      sexObj: [
        { name: 1, value: "男" },
        { name: 2, value: "女" },
      ],
      temp: {
        id: "",
        tenantId: undefined, // 租房id
        optUser: undefined, // 用户id
        name: "",
        sex: "",
        introduction: "",
        isTop: "",
        status: "",
        isTopSort: "",
      },
      searchParam: {
        name: "",
        status: "",
      },

      tableUrl: getCpbTeacherListUrl,
      headers: [
        {
          prop: "isTopSort",
          label: "排序",
        },
        {
          prop: "name",
          label: "姓名",
        },
        {
          prop: "sex",
          label: "性别",
          formatter: (row, prop) => {
            return sexStatusMap[row.sex];
          },
        },
        {
          prop: "introduction",
          label: "简介",
        },
        {
          prop: "status",
          label: "发布状态",
          formatter: (row, prop) => {
            return releaseStatusMap[row.status];
          },
        },
      ],
    };
  },
  methods: {
    // 重置按钮
    reset() {
      this.searchParam.name = "";
      this.searchParam.status = "";
    },
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          ids: item.id,
        };
        this.$axios
          .post(`${deleteCpbTeacherUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },

    handleAdd() {
      this.dialogTitle = "添加";
      this.dialogFormVisible = true;
      this.temp.name = null;
      this.temp.sex = null;
      this.temp.introduction = null;
      this.temp.isTop = null;
      this.temp.status = null;
      this.temp.isTopSort = null;
      this.temp.id = null;
    },
    handleEdit(data) {
      this.dialogTitle = "编辑";
      this.dialogFormVisible = true;
      this.temp.name = data.name;
      this.temp.sex = data.sex;
      this.temp.introduction = data.introduction;
      this.temp.isTop = data.isTop;
      this.temp.status = data.status;
      this.temp.isTopSort = data.isTopSort;
      this.temp.id = data.id;
    },
    saveEdit() {
      console.log(this.temp);
      if (!this.temp.name || this.temp.name == "") {
        this.$message.error("请输入教师姓名");
        return;
      }
      if (!this.temp.sex || this.temp.sex == "") {
        this.$message.error("请选择教师性别");
        return;
      }
      if (!this.temp.introduction || this.temp.introduction == "") {
        this.$message.error("请输入教师简介");
        return;
      }
      if (!this.temp.optUser) {
        this.temp.optUser = this.$store.state.app.userInfo.userId;
      }
      if (!this.temp.tenantId) {
        this.temp.tenantId = this.$store.state.app.userInfo.tenantId;
      }
      this.$axios.post(`${saveCpbTeacherInfoUrl}`, this.temp).then((res) => {
        if (res.code === 200) {
          this.$message.success("操作成功！");
          this.$refs.list.search();
        } else {
        }
      });
      this.dialogFormVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
