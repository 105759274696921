// 发布状态
const releaseStatus = [
  {
    value: 0,
    label: "待发布",
  },
  {
    value: 1,
    label: "已发布",
  },
];
// 性别
const sexStatus = [
  {
    value: 1,
    label: "男",
  },
  {
    value: 2,
    label: "女",
  },
];

import { mapHelper } from "@/utils/common.js";
const { map: releaseStatusMap, setOps: releaseStatusOps } = mapHelper.setMap(releaseStatus);
const { map: sexStatusMap, setOps: sexStatusOps } = mapHelper.setMap(sexStatus);
export { releaseStatus, releaseStatusMap,releaseStatusOps,sexStatusOps, sexStatus, sexStatusMap};